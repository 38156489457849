import { useEffect, useState } from 'react';
import HeaderClock from '../header-clock/HeaderClock';
import { useLocalDateTimeContext, useMagazineContext } from '../../providers/magazine'
import homePageStyle from "./HomePage.module.scss"
import HeaderUserStatus from '../header-user-status/HeaderUserStatus';
import Button from '../button/ButtonComponent';
import clsx from 'clsx';
import { getLastClocking, postClocking } from '../../services/api';
import Loading from '../loading/LoadingComponent';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import CONFIG from '../../services/config';
import { ReactComponent as NetworkIcon } from './icon-network-alert.svg';
import { useTranslation } from 'react-i18next';
import { CLOCKING_DATA, CLOCKING_STATUS, owsDateFormat } from '../../services/interfaces';
import AlertContent from '../alert-content/AlertContent';
import LastClocking from '../last-clocking/LastClocking';
import Alert from '../alert/AlertContainer';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

const isTooEarlyForClicking = (magazineContext: any, clockDatetime: any, localDatetime: Dayjs) => {
  if (!clockDatetime) {
    return false;
  }
  const lastClockingDayjs = dayjs(clockDatetime, owsDateFormat).tz(magazineContext?.magazine?.timezone || CONFIG.DEFAULT_TIMEZONE);
  const diffSecsFromNow = dayjs(localDatetime).tz(magazineContext?.magazine?.timezone || CONFIG.DEFAULT_TIMEZONE).diff(lastClockingDayjs, 'seconds');
  return diffSecsFromNow <= 60;
}

function HomePage() {
  const magazineContext: any = useMagazineContext();
  const [isClockRunning, setClockRunning] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [lastClocking, setLastClocking] = useState<CLOCKING_DATA>({
    inOut: CLOCKING_STATUS.OUT,
    dateTime: '',
    employeeNumber: '',
    isFirstClocking: false
  });
  const [displayAlert, setDisplayAlert] = useState({
    visible: false,
    message: ''
  });
  const [delayed, setDelayed] = useState<boolean>(false);
  const [isUserIpAllowed, setIsUserIpAllowed] = useState<boolean>(false);
  const [secondsBeforeNextAction, setSecondsBeforeNextAction] = useState<number>(0);
  const localDateTime: any = useLocalDateTimeContext();

  // language
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const lang = urlParams.get('lang') || 'nl';
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setDelayed(displayAlert.visible);
    }, 300);
  }, [displayAlert.visible])

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  useEffect(() => {
    getLastClockingData()
  }, [magazineContext]);

  useEffect(() => {
    if (isTooEarlyForClicking(magazineContext, lastClocking?.dateTime, localDateTime)) {
      const currentSeconds = localDateTime.second();
      if (currentSeconds !== 0) {
        return setSecondsBeforeNextAction(60 - currentSeconds);
      }
    } else if (lastClocking?.dateTime) {
      setSecondsBeforeNextAction(0);
    }
  }, [localDateTime, secondsBeforeNextAction]);

  async function getLastClockingData() {
    try {
      const data = await getLastClocking()
      if (!data) {
        throw new Error('No data returned from Clocking Server');
      }
      const isFirstClocking = !data?.Clocking?.dateTime;
      const { dateTime, employeeNumber, inOut } = data?.Clocking || {};

      if (isTooEarlyForClicking(magazineContext, data?.Clocking.dateTime, localDateTime)) {
        const currentSeconds = localDateTime.second();
        setSecondsBeforeNextAction(60 - currentSeconds);
      }

      setIsUserIpAllowed(data.isIpAllowed);
      setLastClocking({ dateTime, employeeNumber, inOut, isFirstClocking });
      setClockRunning(inOut === CLOCKING_STATUS.IN);
    } catch (e: any) {
      if (e.message === "ip-not-allowed") {
        setIsUserIpAllowed(false);
        return;
      }
      if (e.message === "employee-number-error") {
        setDisplayAlert({ visible: true, message: t("employeeNumberError") });
        return;
      }
      setDisplayAlert({ visible: true, message: e.message });
    } finally {
      setLoading(false)
    }
  }

  const toggleRunningClock = async () => {
    const flippedInOut = lastClocking.inOut === CLOCKING_STATUS.IN ? CLOCKING_STATUS.OUT : CLOCKING_STATUS.IN;
    setLoading(true);

    if (isTooEarlyForClicking(magazineContext, lastClocking.dateTime, localDateTime)) {
      const lastClockingDayjs = dayjs(lastClocking.dateTime, owsDateFormat).tz(magazineContext?.magazine?.timezone || CONFIG.DEFAULT_TIMEZONE);
      const diffSecsFromNow = dayjs().tz(magazineContext?.magazine?.timezone || CONFIG.DEFAULT_TIMEZONE).diff(lastClockingDayjs, 'seconds');
      setDisplayAlert({ visible: true, message: t(`wait`, { seconds: 60 - diffSecsFromNow }) })
      return;
    }

    try {
      const data = await postClocking({ inOut: flippedInOut, employeeNumber: lastClocking.employeeNumber })
      const { dateTime, employeeNumber, inOut } = data.Clocking;
      setLastClocking({ dateTime, employeeNumber, inOut });
      setClockRunning(inOut === CLOCKING_STATUS.IN);
      if (isTooEarlyForClicking(magazineContext, data.Clocking.dateTime, localDateTime)) {
        const currentSeconds = dayjs().tz(magazineContext?.magazine?.timezone || CONFIG.DEFAULT_TIMEZONE).second();
        setSecondsBeforeNextAction(60 - currentSeconds);
      }
    } catch (e: any) {
      let errorMessage = '';
      try {
        if (e.message === 'ip-not-allowed') {
          errorMessage = t("ipNotAllowed");
          setIsUserIpAllowed(false);
        } else if (e.message === "employee-number-error") {
          errorMessage = t("employeeNumberError")
        } else {
          errorMessage = `[Request error] - ${e.status} - ${e.message}`;
        }
      } catch (newE) {
        errorMessage = `[Request error] - ${e.message}`;
      }

      setTimeout(() => {
        setDisplayAlert({ visible: true, message: errorMessage });
      }, 300)
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={homePageStyle.Home}>
      {
        magazineContext.error && (<p>{t('magazineError')}...</p>)
      }
      <Alert show={loading}>
        <Loading />
      </Alert>

      <Alert show={displayAlert.visible}>
        <AlertContent setDisplayAlert={setDisplayAlert} displayAlert={displayAlert} delayed={delayed} />
      </Alert>

      {
        magazineContext.magazine && (lastClocking?.dateTime || lastClocking?.isFirstClocking) &&
        <>
          <HeaderClock isTimerOn={isClockRunning} />
          <HeaderUserStatus lastClocking={lastClocking} />
          <div className={homePageStyle.MainContent}>
            {/* If user ip allowed show button */}
            {
              isUserIpAllowed && (
                <div className={homePageStyle.buttonWrapper}>
                  <Button
                    className={clsx(isClockRunning && 'secondary')}
                    onClick={toggleRunningClock}
                    disabled={loading || secondsBeforeNextAction > 0}
                  >
                    {isClockRunning && t('clockOut')}
                    {!isClockRunning && t('clockIn')}
                  </Button>
                </div>
              )
            }
            {/* If user ip not allowed show message and connect icon*/}
            {
              !isUserIpAllowed &&
              <div className={homePageStyle.UnallowedIpAlert}>
                <NetworkIcon />
                {t("ipNotAllowed")}
                <br />
              </div>
            }

            <LastClocking isUserIpAllowed={isUserIpAllowed} secondsBeforeNextAction={secondsBeforeNextAction} lastClocking={lastClocking} />
          </div>
          <div className={homePageStyle.Footer}>
            {t('question')}
          </div>
        </>
      }
    </div >
  );
}

export default HomePage;
